<template>
    <div>
        <div class="card-toolbar mb-5">
            <router-link v-if="$can('subscriptions.create')" to="/purchases/subscriptions/create" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon>
                {{ $t('subscriptions.add_subscription')}}
            </router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="customer_id">{{$t('subscriptions.customer')}}</label>
                            <!-- <select name="" id="customer_id" v-model="filters.customer_id" class="custom-select" >
                                <option v-for="row in customers" :value="row.id" :key="row.id">
                                    {{ row.fullname }}
                                </option>
                            </select> -->
                            <multiselect v-model="customer" :placeholder="$t('subscriptions.customer')"
                                         id="customer_id"
                                         label="fullname"
                                         track-by="id"
                                         :options="customers"
                                         :multiple="false"
                                         :taggable="false"
                                         :show-labels="false"
                                         :show-no-options="false"
                                         :show-no-results="false"
                                         @search-change="getCustomers($event)">
                            </multiselect>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="name">{{$t('subscriptions.name')}}</label>
                            <input v-model="filters.name" type="text" id="name" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_date">{{$t('subscriptions.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_date">{{$t('subscriptions.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_amount">{{$t('subscriptions.from_amount')}}</label>
                            <input v-model="filters.from_amount" type="number" id="from_amount" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_amount">{{$t('subscriptions.to_amount')}}</label>
                            <input v-model="filters.to_amount" type="number" id="to_amount" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">{{$t('status')}}</label>
                            <select name="" id="f_status" v-model="filters.is_active" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                            </select>
                        </div>


                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                              <span><i class="fas fa-search"></i>
                                  <span>{{$t('search')}}</span>
                              </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                              <span><i class="fas fa-trash-restore"></i>
                                  <span>{{$t('reset_search')}}</span>
                              </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-custom">

            <div class="card-body">

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">

                        <b-form-checkbox v-if="$can('subscriptions.change_status')"
                                         size="lg" @change="changeStatus(props.row.id, props.row.is_active)"
                                         v-model="props.row.is_active"
                                         :name="'check-button'+props.row.id"
                                         switch :key="props.row.id">
                        </b-form-checkbox>
                        <b-form-checkbox v-else :disabled="true"
                                         size="lg"
                                         v-model="props.row.is_active"
                                         :name="'check-button'+props.row.id"
                                         switch :key="props.row.id">
                        </b-form-checkbox>
                    </template>
                    <template slot="actions" slot-scope="props">

                        <v-btn v-b-tooltip.hover :title="$t('edit')" v-if="$can('subscriptions.update')" icon color="pink" :to="`/purchases/subscriptions/edit/${props.row.id}`">
                            <v-icon small class="mr-2 text-info">mdi-pencil</v-icon>
                        </v-btn>

                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('subscriptions.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
                    </template>
                </v-server-table>

            </div>
        </div>
        <!--end::customer-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-subscriptions",
        components: {},
        data() {
            return {
                mainRoute: 'purchases/subscriptions',
                routeChangeStatus: 'purchases/subscription/change-status',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    customer_id: null,
                    name: null,
                    from_date: null,
                    to_date: null,
                    is_active: null,
                    from_amount: null,
                    to_amount: null,
                },
                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
                columns: ['name', 'customer_name', 'first_invoice_date', 'total_after_tax', 'generate_invoice_every', 'generate_interval_name', 'status', 'actions'],
                data: [],
                customers: [],
                customer: null,


            }
        },
        watch: {
            customer: function (val) {
                if (val) {
                    this.filters.customer_id = val.id;
                } else {
                    this.filters.customer_id = null;
                }
            },
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('subscriptions.subscription_name'),
                        customer_name: that.$t('subscriptions.customer'),
                        first_invoice_date: that.$t('subscriptions.first_invoice_date'),
                        total_after_tax: that.$t('subscriptions.total'),
                        generate_invoice_every: that.$t('subscriptions.generated'),
                        generate_interval_name: that.$t('subscriptions.every'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.subscriptions_management")}]);

            // this.getCustomers();

        },
        methods: {
            changeStatus(id, status) {
                ApiService.patch(this.routeChangeStatus + '/' + id, {
                    is_active: (status ? 1 : 0),
                }).then((response) => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.customer_id = null;
                this.filters.name = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.is_active = null;
                this.filters.from_amount = null;
                this.filters.to_amount = null;
                this.customer = null;

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },

            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            getCustomers(filter) {
                if(filter && filter.length >= 3)
                ApiService.get(this.mainRouteDependency + "/customers", {params:{filter: filter}}).then((response) => {
                    this.customers = response.data.data;
                });
            },


            actionDelete(item) {
                ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
        },
    };
</script>
